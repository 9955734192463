import { UtilsService } from '../utils-service/utils.service';
import { OtpdialogComponent } from '../../shared/dialogs/otpdialog/otpdialog.component';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import {
  Auth as newAuth,
  ConfirmationResult,
  getAuth,
  signInWithPhoneNumber,
  signOut,
  signInWithCustomToken,
  browserLocalPersistence,
} from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '../ui-service/ui.service';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth: newAuth;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private uiService: UiService,
    private toast: ToastrService,
    private translate: TranslocoService,
    private utils: UtilsService,
    private http: HttpClient
  ) {
    this.auth = getAuth();
    this.auth.languageCode = environment.locale;
  }

  public futLogin(dialCode: string, phone: string, countryCode: string): Observable<boolean> {
    this.uiService.showSpinner = true;
    this.utils.sendEvent('cl_sms_sent');
    return from(this.auth.setPersistence(browserLocalPersistence)).pipe(
      switchMap(() =>
        // Funzione per eseguire la registrazione dell'utente, resituisce una Promessa che si esegue dopo che l'utente si è registrato con successo
        from(signInWithPhoneNumber(this.auth, dialCode + phone, (window as any).recaptchaVerifier))
      ),
      switchMap(registerResult => {
        this.uiService.showSpinner = false;
        return this.showOtpDialog(registerResult, '', dialCode, phone, countryCode);
      }),
      catchError(err => {
        console.log('Login err', err);
        this.uiService.showSpinner = false;
        if (!err) {
          return of(false);
        }
        // Show toast error with parameters
        const regex = /\(([^)]+)\)/;
        const parsedError = regex.exec(err as string);
        let typeError = parsedError ? parsedError[0] : null;
        if (!typeError) return of(false);
        // (auth/wrong-password) -> auth.errors.firebase_errors.wrong-password
        typeError = typeError.split('(').join('').split(')').join('').split('/').join('.errors.firebase_errors.');
        this.toast.error(this.translate.translate('auth.login.errors.general_error') + ' ' + typeError, this.translate.translate(typeError), {
          progressBar: true,
          closeButton: true,
          positionClass: 'toast-bottom-right',
          timeOut: 3000,
        });
        return of(false);
      }),
      finalize(() => (this.uiService.showSpinner = false))
    );
  }

  public loginWithToken(token: string): void {
    signInWithCustomToken(this.auth, token).then(() => {
      localStorage.setItem('is_logged', 'true');
      localStorage.setItem('logged_with_token', 'true');
      this.router.navigate(['/']);
      this.uiService.showSpinner = false;
    });
  }

  public showOtpDialog(confirmationResult: ConfirmationResult, err: string = '', dialCode: string, phone: string, countryCode: string): Observable<boolean> {
    return this.dialog
      .open(OtpdialogComponent, {
        data: { error: err },
        width: 'min-content',
        minWidth: 'min(50vw, 40em)',
        backdropClass: 'onboarding-dialogs',
      })
      .afterClosed()
      .pipe(
        switchMap(code => {
          if (code && code.confirm && code.value) {
            this.uiService.showSpinner = true;
            return from(confirmationResult.confirm(code.value)).pipe(
              map(() => {
                localStorage.setItem('is_logged', 'true');
                this.router.navigate(['/']);
                return true;
              }),
              catchError(() => {
                this.uiService.showSpinner = false;
                return this.showOtpDialog(confirmationResult, 'dialogs.sms_confirm.errors.code_not_valid', dialCode, phone, countryCode);
              }),
              finalize(() => (this.uiService.showSpinner = false))
            );
          } else if (code == 'resend') {
            return this.futLogin(dialCode, phone, countryCode);
          } else return throwError(() => new Error());
        })
      );
  }

  public internalLogin(): Observable<void> {
    return this.http.post<void>(`${environment.be_url}/auth/login`, {});
  }

  public internalLogout(): Observable<void> {
    return this.http.post<void>(`${environment.be_url}/auth/logout`, {});
  }

  public logout(auto_redirect = true): Observable<void> {
    // Funzione per esegure il logut dell'utente

    // Rimuove la variabile 'is_logged'
    localStorage.removeItem('is_logged');
    // Lo riporta sul login

    if (auto_redirect) this.router.navigate(['/login']);

    this.dialog.closeAll();
    // Ed esegue il logout (Remove Vertical cookie + Firebase logout)
    return this.internalLogout().pipe(switchMap(() => from(signOut(this.auth))));
  }
}
